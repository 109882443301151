var break__sm = 480;
var break__md = 700;
var break__lg = 900;

var windowSize = 'xs';

function initResize()
{
    windowSize = getWindowSize();

    $(window).resize(function () {
        checkWindowWidth();
    });
}



function getWindowSize()
{
    var windowWidth = $(window).width();
    var size = 'xs';

    if(windowWidth < break__sm) {
        size = 'xs';
    } else if (windowWidth < break__md) {
        size = 'sm';
    } else if (windowWidth < break__lg) {
        size = 'md';
    } else {
        size = 'lg';
    }

    return size;
}

function checkWindowWidth()
{
    var windowWidth = $(window).width();

    var newWindowSize = getWindowSize();

    if(windowSize != newWindowSize) {
        windowSize = newWindowSize;
        resized();
    }
}

function resized()
{
    window.dispatchEvent(new Event('resized'));
}

initResize();