if($('.js-volgorde-container').length > 0) {
    initVolgorde();
}

function initVolgorde()
{
    window.addEventListener('resized', function (e) {
        updateVolgorde();
    });

    updateVolgorde();
}

function updateVolgorde()
{
    $containers = $('.js-volgorde-container');

    $containers.each(function () {
        veranderVolgorde($(this));
    });
}

function veranderVolgorde($container)
{
    $container.css('flex-direction', 'column');

    $items = $container.children('.js-volgorde-item:not(.hidden)');

    var containerGrootte = { width: $container.width(), height: $container.outerHeight() };
    var itemGrootte = berekenItemGrootte($items);
    var aantalItems = $items.length;
    var aantalKolommen = Math.floor(containerGrootte.width / itemGrootte.width);
    var aantalItemsPerKolom = Math.ceil(aantalItems / aantalKolommen);
    var containerHoogte = aantalItemsPerKolom * itemGrootte.height;

    $container.height(containerHoogte);
}

function berekenItemGrootte($items)
{
    var itemWidth = 0;
    var itemHeight = 0;

    $items.each(function () {

        var width = $(this).width();
        if(width > itemWidth) {
            itemWidth = width;
        }

        var height = $(this).outerHeight(true);
        if(height > itemHeight) {
            itemHeight = height;
        }
    });

    return { width: itemWidth, height: itemHeight };
}