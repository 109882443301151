$(document).ready(function(){
    $('.js-app-info-slider').slick({
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: false,
        dots: true,
        dotsClass: 'app__info-images-slider-dots',
        fade: true,
        infinite: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        useCSS: true,
        useTransform: true
    });
});