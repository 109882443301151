var letterGeselecteerd = '';

if($('.js-filter-plaats-letter').length > 0) {
    initFilter();
}

function initFilter()
{
    deactiveerLettersZonderPlaatsen();
    activeerLettersMetPlaatsen();
}

function deactiveerLettersZonderPlaatsen()
{
    $letters = $('.js-filter-plaats-letter');

    $letters.each(function () {

        var aantal_plaatsen = $('.js-plaats[data-letter="' + $(this).text() + '"]').length;

        if(aantal_plaatsen) {
            return true;
        }

        $(this).addClass('disabled');
        $(this).removeAttr('href');
    });
}

function activeerLettersMetPlaatsen()
{
    $letters = $('.js-filter-plaats-letter:not(.disabled)');

    $letters.each(function () {
        $(this).removeAttr('href');
    });

    $letters.on('click', clickedLetter);
}

function clickedLetter(e)
{
    e.preventDefault();

    var $letter = $(this);

    if($letter.text() == letterGeselecteerd) {
        deselecteerLetter();
        return;
    }

    deselecteerLetter();

    selecteerLetter($letter);
}

function selecteerLetter($letter)
{
    $letter.addClass('active');
    letterGeselecteerd = $letter.text();
    $('.js-plaats').addClass('hidden');
    var $plaatsen = $('.js-plaats[data-letter="' + letterGeselecteerd + '"]');
    $plaatsen.removeClass('hidden');

    if($plaatsen.length == 1) {
        $plaatsen.addClass('een-kolom');
    }

    if($plaatsen.length == 2 || $plaatsen.length == 4) {
        $plaatsen.addClass('twee-kolommen');
    }

    window.dispatchEvent(new Event('resized'));

    woopra.track("filter-plaatsen", {
        letter: $letter.text()
    });
}

function deselecteerLetter()
{
    $('.js-filter-plaats-letter').removeClass('active');
    letterGeselecteerd = '';
    $('.js-plaats').removeClass('hidden een-kolom twee-kolommen');
    window.dispatchEvent(new Event('resized'));
}