$(document).on('click', '.track-club-locatie', function() {
    woopra.track("click-club-locatie", {
        titel: $(this).attr('title'),
        href: $(this).attr('href')
    });
});

$(document).on('click', '.track-club-telefoonnummer', function() {
    woopra.track("click-club-telefoonnummer", {
        titel: $(this).attr('title'),
        href: $(this).attr('href')
    });
});

$(document).on('click', '.track-club-emailadres', function() {
    woopra.track("click-club-emailadres", {
        titel: $(this).attr('title'),
        href: $(this).attr('href')
    });
});

$(document).on('click', '.track-club-website', function() {
    woopra.track("click-club-website", {
        titel: $(this).attr('title'),
        href: $(this).attr('href')
    });
});